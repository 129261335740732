import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

const LogoContainer = ( props ) => (
  <LogoWrapper postMobile={props.postMobile}>
    <StaticImage
      src="../../static/images/the-rising-rose-logo-transparent.png"
      loading="eager"
      alt="The Rising Rose Logo"
    />
  </LogoWrapper>
)

const LogoWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.screen.medium}) {
    display: ${(props) => props.postMobile ? 'none' : 'block'};
    max-width: 75vw;
    transform: translate(17%, 40%);
  }
  
  @media (min-width: ${(props) => props.theme.screen.medium}) {
    margin: 5.4em auto 0;
    display: block;
    width: 32%;
  }
`

export default LogoContainer;
