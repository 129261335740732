import React from 'react'
import Layout from '@components/Layout'
import { Section } from '@styles/globalComponents'
import PageTitle from '@components/PageTitle'
import ContactForm from '@components/ContactForm'
import SEO from '@components/SEO'
import LogoContainer from '@components/Logo'


const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" description="Direct form to contact Olivia Rose on The Rising Rose" />
      <LogoContainer />
      <Section>
        <PageTitle>Contact</PageTitle>
        <ContactForm />
      </Section>
    </Layout>
  )
}

export default Contact
